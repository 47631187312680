import React, { createContext, useReducer } from "react";
import { LayoutReducer } from "../routing/LayoutReducer";
import Alert from "../components/Alert";
export const LayoutContext = createContext();

const layoutInitState = {
  alerts: [],
};

export const LayoutContextProvider = (props) => {
  const [state, dispatch] = useReducer(LayoutReducer, layoutInitState);

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {props.children}
      <Alert />
    </LayoutContext.Provider>
  );
};
