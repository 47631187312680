// https://www.pluralsight.com/guides/how-to-get-a-component-from-anywhere-in-an-app-using-react
export const LayoutReducer = (state, action) => {
  switch (action.type) {
    case "PUSH_ALERT":
      const newAlert = {
        ...action.payload,
        id: getUniqueId(),
      };
      return {
        ...state,
        newAlert: newAlert,
        alerts: [newAlert, ...state.alerts],
      };

    case "REMOVE_ALERT":
      const updatedAlerts = state.alerts.filter(
        (alert) => alert.id !== action.payload.id
      );
      return {
        ...state,
        alerts: updatedAlerts,
      };

    default:
      return state;
  }
};

const getUniqueId = () => {
  return Date.now() + "-" + Math.random();
};
