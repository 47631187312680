import React, { useEffect, useContext } from "react";
import { LayoutContext } from "../routing/LayoutContext";
const Alert = (props) => {
  const { state, dispatch } = useContext(LayoutContext);

  useEffect(() => {
    if (state.alerts.length > 0) {
      let length =
        typeof state.newAlert.timeoutLength !== "undefined" &&
        state.newAlert.timeoutLength > 0
          ? state.newAlert.timeoutLength
          : 4000;

      setTimeout(() => {
        dispatch({
          type: "REMOVE_ALERT",
          payload: {
            id: state.newAlert.id,
          },
        });
      }, length);
    }
  }, [state.newAlert, dispatch, state.alerts.length]);

  return (
    <div className="alert-container">
      {state.alerts.map((alert) => {
        return (
          <div
            key={alert.id}
            className={`alert-item alert-item-type-${alert.type}`}
          >
            <p className="alert-message">{alert.message}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Alert;
