import React, { useEffect } from "react";

const VideoBkg = ({ src, playbackRate, autoPlay, play }) => {
  useEffect(() => {
    var v = document.getElementById("bkg-video");
    v.playbackRate = playbackRate ? playbackRate : 1;
    v.autoPlay = autoPlay ? autoPlay : true;
    v.play = play ? play : true;
  }, [playbackRate, autoPlay, play]);

  return (
    <video id="bkg-video" className="bkg-video" loop autoPlay muted>
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default VideoBkg;
